<template>
    <el-config-provider :locale="zhCn">
        <!-- ({{_ajax.store.user}}) -->
        <router-view v-if="_ajax.store.re_load" />
    </el-config-provider>
</template>
<script>
import zhCn from "element-plus/lib/locale/lang/zh-cn"
import { test } from '@/config/index';

export default {
    name: 'app',
    data() {
        return {
            zhCn,
            form: {},
            isRouterAlive: true,
            re_load: true
        }
    },
    mounted() {
        var in_data = {
            ...this._ajax.get_url_argument_obj(),
            ...this.$route.params,
            ...this.$route.query,
        }
        if (in_data.Authorization_vcase) {
            sessionStorage.Authorization_vcase = in_data.Authorization_vcase
        }
        /*if (test) {
            this._ajax.store.user.ID = "114"
            this._ajax.store.user.account = "001"
            this._ajax.store.user.email = "yk@163.com"
            this._ajax.store.user.msg = "登录成功"
            this._ajax.store.user.name = "liu"
            this._ajax.store.user.school = "产品支持"
            this._ajax.store.user.telephone = "18640195989"
        }*/
        this._ajax.store.user = JSON.parse(localStorage.VCASE_user || '{"permissions_group":[]}')
        this.infoFun()
        // setInterval(this.infoFun, 20 * 1000)
        /*var _this = this
        this._ajax.reLoad = function(argument) {
            _this.re_load = false
            _this.$nextTick(() => {
                _this.re_load = true
            })
        }*/
    },
    methods: {
        infoFun() {
            if (!this.$route.name) {
                return
            }
            this._ajax.post({
                url: "UserStopWep",
                data: {
                    name: this.$route.name
                },
                /*success: (res) => {
                    this._ajax.store.user = res.data
                    localStorage.VCASE_user = JSON.stringify(res.data)
                },*/
            })
            if ((sessionStorage.Authorization_vcase || localStorage.Authorization_vcase) && this.$route.name && this.$route.name != "login") {
                this._ajax.store.user = JSON.parse(localStorage.VCASE_user || "{}")
            } else if (this.$route.name && this.$route.name != "login") {
                // this.routerPush("login")
            }
        }
    },
    watch: {
        $route(a, b) {
            if (!b.name) {
                // console.log("watch", a, b)
                this.infoFun()
            }
        }
    }
}
</script>
<style lang="scss">
</style>