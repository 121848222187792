import { createApp } from 'vue'
import '@/assets/css/element.scss'
import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import LogicFlow from '@logicflow/core';
import '@logicflow/core/dist/style/index.css';
import { DndPanel, SelectionSelect, Menu, NodeResize, Snapshot } from '@logicflow/extension';
import '@logicflow/extension/lib/style/index.css'
import html2canvas from 'html2canvas';
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import ajax from "@/ajax"
import { storeInit } from '@/store/index.js'
import VueParticles from 'vue-particles'

import '@/assets/css/base.scss'
import '@/assets/css/iconfont.css'
import '@/assets/css/animate.css'
router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.permissions && app.config.globalProperties.permissionsTest(to.meta.permissions)) {
        return
    }
    next()
})

var app = createApp(App)
app.use(ElementPlus)
app.use(createPinia())
app.use(router)
app.use(VueParticles)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

LogicFlow.use(DndPanel);
LogicFlow.use(SelectionSelect);
LogicFlow.use(Menu);
LogicFlow.use(NodeResize);
LogicFlow.use(Snapshot);


ajax.store = storeInit()
app.config.globalProperties._ajax = ajax

app.config.globalProperties.LogicFlow = LogicFlow
app.config.globalProperties.html2canvas = html2canvas
app.config.globalProperties.routerReplace = function(name, data) {
    if (!name) {
        return
    }
    this.$router.replace({
        name: name,
        query: data
    });
}
app.config.globalProperties.routerPush = function(name, data) {
    if (!name) {
        return
    }
    /*if (this._ajax.store.user.user_category == '机构') {
        if ([
                //
                "announcement_Third",
                "announcement_Bond",
                "announcement_HongKongShares",
                "announcement_Fund",
                "announcement_Supervise",
                "information_F",
            ].indexOf(name) != -1) {
            this.$message({
                type: 'warning',
                message: "您目前暂无此功能权限。"
            })
            return
        }
    }*/
    this.$router.push({
        name: name,
        query: data
    });
}
app.config.globalProperties.permissionsTest = function(name) {
    if ((ajax.store.user.WebUrlName || []).indexOf(name) == -1) {
        this.$alert('您没有当前功能权限！请前往用户中心-会员中心购买对应权限。', '暂无权限', {
            // showClose: false,
            confirmButtonText: '前往',
            callback: (action) => {
                if (action == "confirm") {
                    this.routerReplace("vip")
                }
            },
        })
        return true
    }
    return false
}
app.config.globalProperties.userQuit = function(name, data) {
    localStorage.Authorization_vcase = ""
    sessionStorage.Authorization_vcase = ""
    localStorage.VCASE_user = ""
    location.href = "/"
}
app.config.globalProperties._textOverTest = function(form, key, num) {
    if (form[key] && form[key].length > num) {
        this.$message({
            type: 'success',
            message: "文字过长"
        })
        form[key] = form[key].slice(0, num)
    }
}
app.config.globalProperties._textKTest_A = function(form, key, k) {
    var arr = form[key].match(k)
    var str = arr.join("")
    if (form[key] != str) {
        this.$message({
            type: 'success',
            message: "文字被整理"
        })
    }
    form[key] = str
}
app.config.globalProperties.tagClose = function(list, index) {
    list.splice(index, 1)
}
app.config.globalProperties.openFun = function(link) {
    if (!link) {
        return
    }
    window.open(link)
}
app.config.globalProperties.goBack = function(link) {
    this.$router.back()
}
app.config.globalProperties.createDownLoadClick = function(content, fileName) {
    const link = document.createElement("a");
    link.href = content
    link.download = fileName;
    var ev = document.createEvent("MouseEvents");
    ev.initMouseEvent(
        "click",
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
    );
    link.dispatchEvent(ev);
}





app.mount('#app')