import AnnouncementRetrievalBg from "@/assets/image/announcement_retrieval-bg2.jpg";
import InformationRetrievalBg from "@/assets/image/information_retrieval-bg2.jpg";
import EmpiricalModelBg from "@/assets/image/empirical_model-bg2.jpg";
import FactorConversion from "@/assets/image/factor_conversion.jpg";
import Features from "@/assets/image/features.jpg";
import MyModel from "@/assets/image/my_model.jpg";
import VipBg from "@/assets/image/vip_bg.jpg";
import ProgrammeAnnouncement from "@/assets/image/programme_announcement.jpg";
import AnnouncementRetrievalBg_3 from "@/assets/image/announcement_retrieval-bg3.png";
import AnnouncementRetrievalBg_4 from "@/assets/image/announcement_retrieval-bg4.jpg";



export const routes = [
    //
    {
        path: '/',
        name: 'login',
        component: () =>
            import('@/views/user/login.vue')
    },
    {
        path: '/register',
        name: 'register',
        component: () =>
            import('@/views/user/register.vue')
    },
    {
        path: '/test',
        name: 'test',
        component: () =>
            import('@/views/test.vue')
    },
    {
        path: '/config',
        name: 'config',
        component: () =>
            import('@/views/config.vue')
    },
    /*{
        path: '/home',
        name: 'home',
        component: () =>
            import('@/views/home.vue'),
        redirect: '/selected_topic',
        children: []
    },*/
    {
        path: '/home',
        name: 'home',
        component: () =>
            import('@/views/home2.vue'),
        redirect: '/vip',
        children: [

            {
                path: 'index',
                name: 'index',
                component: () =>
                    import('@/views/index/index.vue'),
                meta: {
                    title: '案例类别',
                    img: AnnouncementRetrievalBg_4,
                }
            },
            // 案例事实库
            {
                path: 'case_index',
                name: 'case_index',
                component: () =>
                    import('@/views/case/index.vue'),
                meta: {
                    title: '案例事实库',
                    img: AnnouncementRetrievalBg_3,
                }
            },
            {
                path: 'intelligent_filter',
                name: 'intelligent_filter',
                component: () =>
                    import('@/views/case/intelligent_filter.vue'),
                meta: {
                    title: '智能筛选',
                    img: AnnouncementRetrievalBg_3,
                }
            },
            {
                path: 'scheme_preset',
                name: 'scheme_preset',
                component: () =>
                    import('@/views/case/scheme_preset.vue'),
                meta: {
                    title: '方案预设',
                    img: AnnouncementRetrievalBg_3,
                }
            },
            {
                path: 'case_category',
                name: 'case_category',
                component: () =>
                    import('@/views/case/category.vue'),
                meta: {
                    title: '事实分类筛选',
                    img: AnnouncementRetrievalBg_3,
                }
            },
            {
                path: 'case_detail_list',
                name: 'case_detail_list',
                component: () =>
                    import('@/views/case/detail_list.vue'),
                meta: {
                    title: '案例事实库',
                    img: AnnouncementRetrievalBg_3,
                    permissions: "case_detail_list",
                }
            },
            {
                path: 'case_senior_list',
                name: 'case_senior_list',
                component: () =>
                    import('@/views/case/senior_list.vue'),
                meta: {
                    title: '高级检索',
                    img: AnnouncementRetrievalBg_3,
                    permissions: "case_detail_list",
                }
            },
            {
                path: 'case_info',
                name: 'case_info',
                component: () =>
                    import('@/views/case/info.vue'),
                meta: {
                    title: '信息卡片',
                    img: AnnouncementRetrievalBg_3,
                    permissions: "case_detail",
                }
            },
            {
                path: 'opening_report',
                name: 'opening_report',
                component: () =>
                    import('@/views/case/opening_report.vue'),
                meta: {
                    title: '技术路线图',
                    img: AnnouncementRetrievalBg_3,
                    // permissions: "case_detail",
                }
            },
            {
                path: 'research_path',
                name: 'research_path',
                component: () =>
                    import('@/views/case/research_path.vue'),
                meta: {
                    title: '技术路线图',
                    img: AnnouncementRetrievalBg_3,
                    // permissions: "case_detail",
                }
            },
            {
                path: 'case_topics',
                name: 'case_topics',
                component: () =>
                    import('@/views/case/topics.vue'),
                meta: {
                    title: '选题评价',
                    img: AnnouncementRetrievalBg_3,
                    permissions: "case_detail",
                }
            },
            {
                path: 'case_event',
                name: 'case_event',
                component: () =>
                    import('@/views/case/event.vue'),
                meta: {
                    title: '案例事实描述统计分析',
                    img: AnnouncementRetrievalBg_3,
                    permissions: "case_detail",
                }
            },
            {
                path: 'case_firm',
                name: 'case_firm',
                component: () =>
                    import('@/views/case/firm.vue'),
                meta: {
                    title: '描述统计',
                    img: AnnouncementRetrievalBg_3,
                    permissions: "case_detail",
                }
            },
            {
                path: 'case_sequence',
                name: 'case_sequence',
                component: () =>
                    import('@/views/case/sequence.vue'),
                meta: {
                    title: '事件序列',
                    img: AnnouncementRetrievalBg_3,
                    permissions: "case_detail",
                }
            },
            // 案例选题库
            {
                path: '/selected_topic',
                name: 'selected_topic',
                component: () =>
                    import('@/views/selected_topic/index.vue'),
                meta: {
                    title: '案例选题库',
                    img: AnnouncementRetrievalBg_3,
                }
            },
            {
                path: '/topic_filter',
                name: 'topic_filter',
                component: () =>
                    import('@/views/selected_topic/topic_filter.vue'),
                meta: {
                    title: '事实优先',
                    img: AnnouncementRetrievalBg_3,
                    permissions: "topic_filter",
                }
            },
            {
                path: '/topic_filter_ProblemSpace',
                name: 'topic_filter_ProblemSpace',
                component: () =>
                    import('@/views/selected_topic/topic_filter_ProblemSpace.vue'),
                meta: {
                    title: '问题优先',
                    img: AnnouncementRetrievalBg_3,
                    permissions: "topic_filter",
                }
            },
            {
                path: '/topic_random',
                name: 'topic_random',
                component: () =>
                    import('@/views/selected_topic/topic_random.vue'),
                meta: {
                    title: '随机选题',
                    img: AnnouncementRetrievalBg_3,
                    permissions: "topic_random",
                }
            },
            {
                path: '/topic_intelligence',
                name: 'topic_intelligence',
                component: () =>
                    import('@/views/selected_topic/topic_intelligence.vue'),
                meta: {
                    title: '智能选题',
                    img: AnnouncementRetrievalBg_3,
                    permissions: "topic_random",
                }
            },
            {
                path: '/topic_innovate',
                name: 'topic_innovate',
                component: () =>
                    import('@/views/selected_topic/topic_innovate.vue'),
                meta: {
                    title: '创新选题',
                    img: AnnouncementRetrievalBg_3,
                    permissions: "topic_random",
                }
            },
            {
                path: '/topic_directional',
                name: 'topic_directional',
                component: () =>
                    import('@/views/selected_topic/topic_directional.vue'),
                meta: {
                    title: '定向选题',
                    img: AnnouncementRetrievalBg_3,
                }
            },
            // 案例事实库
            {
                path: 'roadmap',
                name: 'roadmap',
                component: () =>
                    import('@/views/roadmap.vue'),
                meta: {
                    title: '技术路线图',
                    img: AnnouncementRetrievalBg_3,
                    // permissions: "tool",
                }
            },
            // 案例事实库
            {
                path: 'tool',
                name: 'tool',
                component: () =>
                    import('@/views/tool/index.vue'),
                meta: {
                    title: '案例工具库',
                    img: AnnouncementRetrievalBg_3,
                    // permissions: "tool",
                }
            },
            {
                path: "/smartFinancialAnalysis",
                name: "smartFinancialAnalysis",
                component: () =>
                    import("@/views/tool/iframeFinanceHelper.vue"),
                meta: {
                    title: "智能财务分析",
                    img: AnnouncementRetrievalBg_3,
                },
            },
            {
                path: "/smartPerformanceEvaluation",
                name: "smartPerformanceEvaluation",
                component: () =>
                    import("@/views/tool/iframeFinanceHelper.vue"),
                meta: {
                    title: "智能业绩评价",
                    img: AnnouncementRetrievalBg_3,
                },
            },
            {
                path: "/smartFinancialAudit",
                name: "smartFinancialAudit",
                component: () =>
                    import("@/views/tool/iframeFinanceHelper.vue"),
                meta: {
                    title: "智能财务审计",
                    img: AnnouncementRetrievalBg_3,
                },
            },
            // 案例筛选统计
            {
                path: 'sift',
                name: 'sift',
                component: () =>
                    import('@/views/sift/index.vue'),
                meta: {
                    title: '案例筛选统计',
                    img: AnnouncementRetrievalBg_3,
                }
            },
            // 案例知识库
            {
                path: 'research_methods',
                name: 'research_methods',
                component: () =>
                    import('@/views/research_methods/index.vue'),
                meta: {
                    title: '案例知识库',
                    img: AnnouncementRetrievalBg_3,
                }
            },
            // 实证模型库
            {
                path: '/model_list',
                name: 'model_list',
                component: () =>
                    import('@/views/my_model/model_list.vue'),
                meta: {
                    title: '实证模型库',
                    img: AnnouncementRetrievalBg_3,
                }
            },
            {
                path: '/model',
                name: 'model',
                component: () =>
                    import('@/views/my_model/model.vue'),
                meta: {
                    title: '实证模型库',
                    img: AnnouncementRetrievalBg_3,
                    permissions: "model_list",
                }
            },
            // 论文智能评阅
            {
                path: 'thesis',
                name: 'thesis',
                component: () =>
                    import('@/views/thesis/index.vue'),
                meta: {
                    title: '论文智能评阅',
                    img: AnnouncementRetrievalBg_3,
                }
            },
            // document
            {
                path: 'document',
                name: 'document',
                component: () =>
                    import('@/views/document.vue'),
                meta: {
                    title: '帮助文档',
                    img: AnnouncementRetrievalBg_3,
                }
            },
            // 用户中心
            {
                path: 'user_info',
                name: 'user_info',
                component: () =>
                    import('@/views/user/user_info.vue'),
                meta: {
                    title: '个人中心',
                    img: VipBg,
                }
            },
            {
                path: 'vip',
                name: 'vip',
                component: () =>
                    import('@/views/user/vip.vue'),
                meta: {
                    title: '会员中心',
                    img: VipBg,
                }
            },
            {
                path: 'agreement',
                name: 'agreement',
                component: () =>
                    import('@/views/user/agreement.vue'),
                meta: {
                    title: '相关协议',
                    img: VipBg,
                }
            },



        ]
    },


]