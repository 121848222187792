import axios from './axios.js';
import Qs from 'qs';
// import { Dialog } from 'vant';
import { ElMessage } from 'element-plus'
import { test } from '@/config/index';

var base_url = "http://49.232.135.204:9874"
var open_url = "http://192.168.1.72:8030"
var ceds_url = "http://ceds.pub:8080"
var aie = "//mpacc.pub/"
if (test) {
    console.log("开发环境")
    // base_url = config_url || 'http://192.168.0.30:8080' //李健洋
    // base_url = config_url || 'http://192.168.1.41:9100' //杨旭
    // base_url = config_url || 'http://192.168.1.72:8030' //72
    // base_url = config_url || 'http://1.13.246.53:2310' //正式
    // base_url = config_url || 'http://1.13.246.53:1386' //正式
    open_url = config_open || 'http://1.13.246.53:1366' //正式
    ceds_url = config_ceds || 'http://82.156.85.106:8080' //正式
} else {
    console.log("生产环境")
    base_url = config_url || 'http://192.168.1.72:8030' //正式
    open_url = config_open || 'http://1.13.246.53:1366' //正式
    ceds_url = config_ceds || 'http://ceds.pub:8080' //正式
    // aie = "//119.45.235.140:4120/";
    aie = "//mpacc.pub/"
}

var url = {
    open_url: open_url,
    echarts: "/static/echarts/index.html?x=" + Math.random(),
    Login: base_url + "/Api/V1/Login/Login",
    UserStopWep: base_url + "/Api/V1/Login/UserStopWep",
    AddUserInfo: base_url + "/Api/V1/D_User/AddUserInfo",
    GetSchoolList: base_url + "/Api/V1/D_User/GetSchoolList",
    GetUserInfo: base_url + "/Api/V1/D_User/GetUserInfo",
    UpdateUserInfo: base_url + "/Api/V1/D_User/UpdateUserInfo",
    UpdateUserInfoTel: base_url + "/Api/V1/D_User/UpdateUserInfoTel",
    UpdateUserPwd: base_url + "/Api/V1/D_User/UpdateUserPwd",
    RetrieveUserPwd: base_url + "/Api/V1/D_User/RetrieveUserPwd",
    

    SendSms: base_url + "/Api/V1/Log_SMS/SendSms",

    

    


    GetM_Category_L: base_url + "/Api/V1/GetCategoryDataController/GetM_Category_L",


    GetCategory: base_url + "/Api/V1/M_Category_L/GetCategory",
    GetCaseFactGraph: base_url + "/Api/V1/M_Category_L/GetCaseFactGraph",
    GetCategoryList: base_url + "/Api/V1/GetCategoryDataController/GetCategoryList",
    GetIndustryList: base_url + "/Api/V1/GetCategoryDataController/GetIndustryList",
    GetProvinceList: base_url + "/Api/V1/GetCategoryDataController/GetProvinceList",
    GetM_CategoryData: base_url + "/Api/V1/GetCategoryDataController/GetM_CategoryData",

    GetCategoryDetail: base_url + "/Api/V1/GetCategoryDataController/GetCategoryDetail",
    GetTenYearDataList: base_url + "/Api/V1/GetCategoryDataController/GetTenYearDataList",
    GetThoseYearsDataList: base_url + "/Api/V1/GetCategoryDataController/GetThoseYearsDataList",

    GetCategoryData: base_url + "/Api/V1/M_CategoryData/GetCategoryData",
    GetQuestionWordCloudData: base_url + "/Api/V1/M_CategoryData/GetQuestionWordCloudData",
    GetMethodWordCloudData: base_url + "/Api/V1/M_CategoryData/GetMethodWordCloudData",
    GetLiteratureList: base_url + "/Api/V1/GetCategoryDataController/GetLiteratureList",
    GetTopicEvaluation: base_url + "/Api/V1/M_CategoryData/GetTopicEvaluation",

    GetDescriptiveStatisticsOfFeaturesData_PropertyNature: base_url + "/Api/V1/GetCategoryDataController/GetDescriptiveStatisticsOfFeaturesData_PropertyNature",
    GetDescriptiveStatisticsOfFeaturesData_Industry: base_url + "/Api/V1/GetCategoryDataController/GetDescriptiveStatisticsOfFeaturesData_Industry",
    GetDescriptiveStatisticsOfFeaturesData_Area: base_url + "/Api/V1/GetCategoryDataController/GetDescriptiveStatisticsOfFeaturesData_Area",
    GetDescriptiveStatisticsOfFeaturesData_ListingSector: base_url + "/Api/V1/GetCategoryDataController/GetDescriptiveStatisticsOfFeaturesData_ListingSector",
    GetNumericalStatisticsData: base_url + "/Api/V1/GetCategoryDataController/GetNumericalStatisticsData",
    DescriptiveStatisticsOfFeaturesDataExportport: base_url + "/Api/V1/Common/DescriptiveStatisticsOfFeaturesDataExportport",
    NumericalStatisticsDataExport: base_url + "/Api/V1/Common/NumericalStatisticsDataExport",

    GetEventTimeSeries: base_url + "/Api/V1/GetCategoryDataController/GetEventTimeSeries",

    GetTheoreticalMethodName: base_url + "/Api/V1/GetCategoryDataController/GetTheoreticalMethodName",
    GetMethodType: base_url + "/Api/V1/M_MethodType/GetMethodType",
    AddMethodView: base_url + "/Api/V1/M_MethodType/AddMethodView",

    GetCategoryAll: base_url + "/Api/V1/M_CategoryData/GetCategoryAll",
    GetQuestionWordCloudDataByName: base_url + "/Api/V1/M_CategoryData/GetQuestionWordCloudDataByName",
    GetMethodWordByCategoryName: base_url + "/Api/V1/M_CategoryData/GetMethodWordByCategoryName",
    CardInformationByCase: base_url + "/Api/V1/M_CategoryData/CardInformationByCase",
    GetMethodDocumentByCategory: base_url + "/Api/V1/M_CategoryData/GetMethodDocumentByCategory",

    GetPbiLink: base_url + "/Api/V1/Table_PbiLink/GetPbiLink",

    GetRandomTopicSelection: base_url + "/Api/V1/M_CategoryData/GetRandomTopicSelection",
    GetCompanyCodeList: base_url + "/Api/V1/M_CategoryData/GetCompanyCodeList",
    CreateCaseByCompany: base_url + "/Api/V1/M_CategoryData/CreateCaseByCompany",
    GetCategoryAll: base_url + "/Api/V1/M_CategoryData/GetCategoryAll",
    GetQuestionWordCloudDataByNameInnovate: base_url + "/Api/V1/M_CategoryData/GetQuestionWordCloudDataByNameInnovate",
    GetMethodWordByCategoryNameInnovate: base_url + "/Api/V1/M_CategoryData/GetMethodWordByCategoryNameInnovate",
    Common_GetCategoryList: base_url + "/Api/V1/Common/GetCategoryList",


    GetPbiLinkList: base_url + "/Api/V1/Table_PbiLink/GetPbiLinkList",
    //
    
    AddUserPayData: base_url + "/Api/V1/D_UserPayData/AddUserPayData",
    GetUserM_LicenseDays: base_url + "/Api/V1/D_UserLicenseType/GetUserM_LicenseDays",
    GetLicenseTypeList: base_url + "/Api/V1/M_LicenseType/GetLicenseTypeList",
    GetUserPayResult: base_url + "/Api/V1/D_UserPayData/GetUserPayResult",
    GetUserCategory: base_url + "/Api/V1/Table_PbiLink/GetUserCategory",

    AddCustomSetting: base_url + "/Api/V1/M_CategoryData/AddCustomSetting",
    GetCustomSetting: base_url + "/Api/V1/M_CategoryData/GetCustomSetting",
    GetUserTypeUseData: base_url + "/Api/V1/D_UserTypeUseData/GetUserTypeUseData",



    //
    get_custom_model_list: base_url + "/Api/V1/GetCaseModelController/get_custom_model_list",

    get_factor: base_url + "/Api/V1/GetCaseModelController/get_factor",
    get_experimental_group: base_url + "/Api/V1/GetCaseModelController/get_experimental_group",
    get_reference_group: base_url + "/Api/V1/GetCaseModelController/get_reference_group",
    save_custom_model: base_url + "/Api/V1/GetCaseModelController/save_custom_model",
    get_custom_model_by_id: base_url + "/Api/V1/GetCaseModelController/get_custom_model_by_id",
    delete_custom_model: base_url + "/Api/V1/GetCaseModelController/delete_custom_model",
    search_by_attribute: base_url + "/Api/V1/GetCaseModelController/search_by_attribute",
    search_by_condition: base_url + "/Api/V1/GetCaseModelController/search_by_condition",
    demonstration_run: base_url + "/Api/V1/GetCaseModelController/demonstration_run",
    get_sample_data: base_url + "/Api/V1/GetCaseModelController/get_sample_data",
    custom_data_demonstration_work: base_url + "/Api/V1/GetCaseModelController/custom_data_demonstration_work",

    file_upload: ceds_url + "/vcase/file_upload",


    GetCKLsit: base_url + "/Api/V1/D_CK_Thesis/GetCKLsit",
    GetresearchpathList: base_url + "/Api/V1/D_researchpath/GetresearchpathList",
    
    GetHomeDataList: base_url + "/Api/V1/GetCategoryDataController/GetHomeDataList",
    GetHomeCompanyCodeList: base_url + "/Api/V1/GetCategoryDataController/GetHomeCompanyCodeList",
    GetPbiLinkList: base_url + "/Api/V1/Table_PbiLink/GetPbiLinkList",
    PerformanceDataExportport: base_url + "/Api/V1/Common/PerformanceDataExportport",


    GetPaperList: base_url + "/Api/V1/GetCategoryDataController/GetPaperList", //开题报告

    GetQusetionTypeList: base_url + "/Api/V1/M_QusetionType/GetQusetionTypeList", //问题优先类别选择
    GetSelectQusetionTypeList: base_url + "/Api/V1/M_QusetionType/GetSelectQusetionTypeList", //问题优先类别选择
    GetLiteratureByQuestionList: base_url + "/Api/V1/GetCategoryDataController/GetLiteratureByQuestionList", //问题优先类别选择
    GetCategoryDataList: base_url + "/Api/V1/M_QusetionType/GetCategoryDataList", //问题优先类别选择


    GetCaseQuotaTypeList: base_url + "/Api/V1/M_CaseQuotaType/GetCaseQuotaTypeList", //获取项目类别
    intelligent_GetCategoryList:base_url + "/Api/V1/M_CaseQuotaType/GetCategoryList",
    intelligent_GetCategoryDetail:base_url + "/Api/V1/M_CaseQuotaType/GetCategoryDetail",

    // 方案预设
    GetCaseQuotaTypeCofigList:base_url + "/Api/V1/M_CaseQuotaType/GetCaseQuotaTypeCofigList",
    GetCaseQuotaTypeCofigWhereList:base_url + "/Api/V1/M_CaseQuotaType/GetCaseQuotaTypeCofigWhereList",
    DetailDataForCase:base_url + "/Api/V1/GetCategoryDataController/GetCompanyDetailDataForCase",

    // chat ai
    GetAIMenu:base_url + "/Api/V1/D_AIQuestion/GetAIMenu",
    GetAIQuestion:base_url + "/Api/V1/D_AIQuestion/GetAIQuestion", // 预设问题获取
    GetAIQuestionDemo:base_url + "/Api/V1/D_AIQuestion/GetAIQuestionDemo",
    UpdateAIQuestion:base_url + "/Api/V1/D_AIQuestion/UpdateAIQuestion",


    GetPaperAI:base_url + "/Api/V1/D_AIQuestion/GetPaperAI", //论文质量检查













}

var obj = {
    url,
    aie,
    post(config) {
        var p_url = url[config.url] || config.url
        config.data = config.data || {}
        // config.data.creater = "1"
        var set = {
            headers: {
                // HeaderKey: 1
            }
        }
        if (config.file) {
            set.responseType = "blob"
        }
        if (config.url != 'Login' && (sessionStorage.Authorization_vcase || localStorage.Authorization_vcase)) {
            config.data.Header = {
                Guid: (sessionStorage.Authorization_vcase || localStorage.Authorization_vcase),
                ...this.store.user
            }
            // config.data.Guid = localStorage.Authorization_vcase
        }
        return axios.post(p_url, config.data, set)
            .then(function(res) {
                if (config.file) {
                    var blob = new Blob([res.data], {
                        type: {
                            xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
                        } [config.fileType]
                    });
                    window.open(window.URL.createObjectURL(blob))
                    return
                }
                res.data.message = res.data.message || res.data.Msg
                if (res.data || res.data.toString) {
                    if (res.data.Status != 0) {
                        config.no_message || ElMessage({
                            message: "提示:" + res.data.message,
                            type: 'error',
                        })
                        if (config.fail) {
                            config.fail(res.data)
                            return
                        }
                        if (res.data.Status == 502 && "login" != config.url) {
                            if (test) {
                                return
                            }
                            localStorage.Authorization_vcase = ""
                            sessionStorage.Authorization_vcase = ""
                            localStorage.VCASE_user = ""
                            location.href = "/"
                        }
                        /*Notification.error({
                            title: '提示',
                            message: res.data.msg
                        })*/
                        return
                    }
                    /*Notification.success({
                        title: '提示',
                        message: res.data.msg
                    })*/
                    if (res.data.Results && res.data.Results.code) {
                        if (res.data.Results.code != 200) {
                            config.no_message || ElMessage({
                                message: "提示:" + res.data.Results.message,
                                type: 'error',
                            })
                            if (config.fail) {
                                config.fail(res.data.Results)
                                return
                            }
                            return
                        }
                        config.success && config.success(res.data.Results)
                        return
                    }
                    config.success && config.success(res.data)
                    return
                }
                ElMessage({
                    message: "接口返回为空",
                    grouping: true,
                    type: 'error',
                })
            })
            .catch(function(error) {
                var err_res = {
                    message: "接口返回失败",
                }
                if (error.response) {
                    err_res.message = {
                        "Internal Server Error": "内部服务器错误",
                    } [error.response.statusText] || (error.response.data && error.response.data.Message) || "接口返回失败"
                } else {
                    err_res.message = {
                        "Network Error": "网络连接失败",
                    } [error.message] || "页面程序错误"
                }
                config.no_message || ElMessage({
                    message: "提示:" + err_res.message,
                    type: 'error',
                })
                console.log("error", [error])
                if (config.fail) {
                    config.fail(err_res)
                    return
                }
                if (!error.response) {
                    console.error(error.stack)
                }
            });
    },
    get(config) {
        var p_url = url[config.url] || config.url
        config.data = config.data || {}
        /*
            ID: "114"
            account: "001"
            email: "yk@163.com"
            msg: "登录成功"
            name: "liu"
            school: "产品支持"
            telephone: "18640195989"
        */
        if (this.store.user.ID) {
            config.data.school = this.store.user.school
            config.data.account = this.store.user.account
            config.data.name = this.store.user.name
        }
        axios.get(p_url, config.data)
            .then(function(res) {
                if (res.data || res.data.toString) {
                    if (res.data.errCode) {
                        if (config.fail) {
                            config.fail(res.data)
                            return
                        }
                        ElMessage({
                            message: "提示:" + res.data.msg,
                            type: 'error',
                        })
                        /*Notification.error({
                            title: '提示',
                            message: res.data.msg
                        })*/
                        return
                    }
                    /*Notification.success({
                        title: '提示',
                        message: res.data.msg
                    })*/
                    config.success && config.success(res.data)
                    return
                }
                ElMessage({
                    message: "接口返回为空",
                    grouping: true,
                    type: 'error',
                })
            })
            .catch(function(error) {
                if (config.fail) {
                    config.fail({
                        errCode: 500,
                        msg: "接口返回失败"
                    })
                } else {
                    ElMessage({
                        message: "接口返回失败",
                        grouping: true,
                        type: 'error',
                    })
                }
                config.error && config.error({
                    url: p_url
                })
            });
    },
    url_obj() {
        return Qs.parse(location.search.replace("?", ""))
    },
    url_str(obj, _h = "") {
        return _h + Qs.stringify(obj)
    },
    get_url_argument(name) {
        var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
        var r = decodeURI(window.location.search).substr(1).match(reg)
        if (r != null) return unescape(r[2])
        return null
    },
    get_url_argument_obj() {
        var obj = {}
        var reg = new RegExp('([^\\?|&]*)=([^&]*)', 'g')
        var r = decodeURI(window.location.search).match(reg) || []
        for (var i = 0; i < r.length; i++) {
            var t = r[i].split('=')
            obj[t[0]] = t[1]
        }
        return obj
    },
    url_passer(obj, key) {
        var _this_ = this
        var returnStr = ''
        switch (typeof obj) {
            case 'string':
            case 'number':
            case 'boolean':
                returnStr += '&' + key + '=' + obj
                break
            default:
                for (var i in obj) {
                    if (typeof obj[i] !== 'function') {
                        if (key && key !== '?' && key !== '&') {
                            returnStr += _this_.url_passer(obj[i], key + '[' + i + ']')
                        } else {
                            returnStr += _this_.url_passer(obj[i], i)
                        }
                    }
                }
                break
        }
        switch (key) {
            case '&':
                return returnStr.replace(/^./, '')
            case '?':
                returnStr = "?" + returnStr
        }
        /*if (key === '?') {
            return returnStr.replace(/^./, '?')
        } else if (key === '&') {
            return returnStr.replace(/^./, '')
        }*/
        return returnStr
    },
    column_prop_filter(item, first = [], not = []) {
        var arr = []
        var keys = Object.keys(item)
        // console.log("column_prop_filter",keys,first,not)
        arr = first.filter((a_item) => {
            return keys.indexOf(a_item) != -1
        })
        arr = arr.concat(keys.filter((a_item) => {
            return [...first, ...not].indexOf(a_item) == -1
        }))
        return arr
    },
    list_page_filter(list, page = 1, page_size = 10) {
        var arr = [...list]
        arr = arr.slice((page - 1) * page_size, page * page_size)
        return arr
    },
    arr_a_filter_b(a, b = []) {
        return a.filter((a_item) => {
            return b.indexOf(a_item) == -1
        });
    },
    str_filter(val, _config, recursion) {
        _config = {
            s: 6,
            e: 4,
            ..._config
        }
        if (!val) {
            return val
        }
        return val.substring(0, _config.s) + "..." + val.substring(val.length - _config.e)
    },
    /*testFun(){
        console.log("testFun",this)
    }*/
}
// String.prototype._ajax = obj
// String.prototype._testFun = obj.testFun

export default obj